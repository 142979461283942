/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
      <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s - ${site.siteMetadata.title}`}
      >

        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest"/>
        <link
            rel="mask-icon"
            href="/favicon/safari-pinned-tab.svg"
            color="#0069b4"
        />
        <meta name="apple-mobile-web-app-title" content="HGV Erdmannhausen"/>
        <meta name="application-name" content="HGV Erdmannhausen"/>
        <meta name="msapplication-TileColor" content="#0069b4"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta name="description" content={metaDescription}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={metaDescription}/>
        <meta property="og:type" content='website'/>
        <meta name="twitter:card" content='summary'/>
        <meta name="twitter:creator" content={site.siteMetadata.author}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={metaDescription}/>
      </Helmet>
  );
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
