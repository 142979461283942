import React from "react"

import "./header.sass"
import ReactDOM from "react-dom";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isActive: false, height: "0" };
    }

    toggle() {
        if (!this.state.isActive) {
            const thisEl = ReactDOM.findDOMNode(this);
            const height = thisEl.getElementsByClassName('nav-items')[0].scrollHeight;
            this.setState({
                height: height + 'px',
                isActive: true
            });
        } else {
            this.setState({
                isActive: false
            });
        }
    }

    render() {
        return (<div
                className={'header'}
            >
                <div
                    className={'container'}
                >
                    <div className="logo">
                        <a href="/">
                            <img src="/images/logo.png" alt=""/>
                        </a>
                    </div>

                    <div className={`nav-items-wrapper${this.state.isActive ? '' : ' mobile-inactive'}`} style={{height: this.state.height}}>
                        <div className="nav-items">
                            <a href="/aktuelles" className="nav-item">AKTUELLES</a>
                            <a href="/termine" className="nav-item">TERMINE</a>
                            <a href="/ueber-uns" className="nav-item">ÜBER UNS</a>
                            <a href="/mitglieder" className="nav-item">MITGLIEDER</a>
                            <a href="/kontakt" className="nav-item">KONTAKT</a>
                        </div>
                    </div>

                    <div className="nav-button" onClick={() => this.toggle()}>
                        <span></span>
                    </div>

                </div>
            </div>
        );
    }
}

export default Header
