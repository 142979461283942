/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, StaticQuery } from "gatsby"

import Header from "../header/header"
import "./layout.sass"
import Footer from "../footer/footer";

class Layout extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <>
                <Header siteTitle={this.props.data.site.siteMetadata.title}/>
                <div className={'layout'}
                >
                    <main>{this.props.children}</main>
                </div>
                <Footer></Footer>
            </>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default props => (
    <StaticQuery
        query={graphql`

        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
        render={data => <Layout data={data}{...props}></Layout>}
    />
);
