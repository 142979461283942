import React from "react";
import PropTypes from "prop-types"

import "../footer/footer.sass"

const Footer = ({children}) => {

    return (
            <div
                className={'footer'}
            >
                <div className={'container'}>
                    <span>

                    <span className={'footerItem'}>
                        <p>Copyright © {new Date().getFullYear()} - HGV Erdmannhausen</p>
                    </span>
                    <span className={'footerItem'}>
                    <a href="/datenschutz">Datenschutz</a>
                    </span>
                    <span className={'footerItem'}>
                    <a href="/impressum">Impressum</a>
                    </span>
                    </span>
                    <span>
                        <p>Made by </p><a target="_blank" rel="noopener"
                                          href="https://foolsparadise.de">fool's paradise</a>
                    </span>
                </div>
            </div>
    )
};

Footer.propTypes = {
    children: PropTypes.node,
};

export default Footer
